import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import ThirdImg from '../Images/Mask group3.png'
import { MdCheckCircle, } from 'react-icons/md'

const primeEstateDetail = () => {
  return (
    <div className='home_class'>
      <div className='overlapNav'>
        <Header/>
          <section className='about_us-container'>
            <div className="container aboutContainerOne">
              <div className="about_text-header">
                <h1 className="wow animate__fadeInUp" data-wow-duration="1.8s">Prime Estate</h1>
                <p className=" wow animate__fadeInUp" data-wow-duration="3s">A very lucrative property investment opportunity in a strategic location in ughelli express road, well positioned for residential development and good return of investment.</p>
            </div>
          </div>
         </section>
      </div>

      <div className='about_first-section'>
        <div className="about_firstImg wow animate__fadeInUp" data-wow-duration="1.8s">
        <img src={ThirdImg} className='service_sellImg' alt="avatar" />
        </div>
      </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>Prime estate is an approved estate layout in Uvwiamuge by (UPU) Urhobo cultural center, ughelli express road delta state. </p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>Currently selling for 1.2 million naira per plot 465sqm and installment plan available.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <h5>TITLE:</h5><p>Freehold, Registered Survey & Deed of conveyance</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <h5>ESTATE FEATURES:</h5>
            <div className="estate_detail">
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Perimeter fencing</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Gate house</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Good Road Network</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Drainage system</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Serene Environment</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Security</p></div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Affordability</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>High Returns on Investment</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>And lots more</p> </div> </div>
            </div>
          </div>
        </div>

      <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="3s" data-wow-offset="150">
            <p>For site inspection and further inquiries, Call <a href="tel" className='telLink'>+234 902 214 1201</a> or <a href="tel" className='telLink'>+2349160559521</a></p>
          </div>
        </div>









        <Footer/>
    </div>
  )
}

export default primeEstateDetail