import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import WOW from 'wowjs';
import Logo from '../Images/logo.jpeg'
import { FaBars } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Header = () => {

  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [isNavShowimg, setIsNavShowing] = useState(window.innerWidth < 800 ? false : true);

  const closeNavHandler = () => {
    if (window.innerWidth < 800) {
      setIsNavShowing(false)
    } else {
      setIsNavShowing(true)
    }
  }
  return (
    <>

      <nav className="wow animate__fadeInDown" data-wow-duration="1.5s">


        <div className='container nav_container'>
          <Link to="/" className='nav_logo1' onClick={closeNavHandler}>
            <img src={Logo} alt="Navbar Logo" />
          </Link>
          {isNavShowimg && <ul className='nav_menu'>
            <li><Link to="/about" onClick={closeNavHandler}>About Us</Link></li>
            <li><Link to="/properties" onClick={closeNavHandler}>Properties</Link></li>
            <li><Link to="/" className='nav_logo'><img src={Logo} alt="Navbar Logo" /></Link></li>
            <li><Link to="/services" onClick={closeNavHandler}>Services </Link></li>
            <li><Link to="/contact_us" onClick={closeNavHandler}>Contact Us</Link></li>
          </ul>}
          <button className='nav_toggle-btn' onClick={() => setIsNavShowing(!isNavShowimg)}>
            {isNavShowimg ? <AiOutlineClose /> : <FaBars />}
          </button>
        </div>
      </nav>

      <ToastContainer
        toastStyle={{
          zIndex: "9999 !important",
        }}
      />

    </>
  )
}

export default Header