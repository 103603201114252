import React, { useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import { Form } from 'react-bootstrap'

const ContactUs = () => {

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    message: '',
  })   

  const changeInputhandler = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const registerUser = async (e) => {
    console.log(userData)
  }


  return (
    <div className='home_class'>

        <Header/>


        <section>
            <div className="contact_us_flex">
              {/* <div className="contactUs_img_carrier">
                <div className="contactUs_img wow animate__fadeInUp" data-wow-duration="1.8s">
                  <img src={Avatar} className='contact_img' alt="Contact img" />
                </div>
              </div> */}

              <div className="contactUs_form_carrier">
                <div className="contactUs_form">
                  <h2 className='inTouch wow animate__fadeInUp' data-wow-duration="1.8s">Let's Get In Touch</h2>
                  <p  className='wow animate__fadeInUp' data-wow-duration="2.5s">Or just reach out manually on <a href="mailto:help.grajahmic@gmail.com">info@grajahmichomes.com</a></p>


                  <Form onSubmit={registerUser}>
                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="3.5s" controlId='exampleForm.ControlInput1'>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type='text'
                      name="name"  // Ensure this matches the key in the state
                      value={userData.name}
                      onChange={changeInputhandler}
                      placeholder='Enter your full name'
                    />
                  </Form.Group>


                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="4s" controlId='exampleForm.ControlInput1'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      name="email"  // Ensure this matches the key in the state
                      value={userData.email}
                      onChange={changeInputhandler}
                      className='removeBg'
                      placeholder='Enter your email address'
                    />
                  </Form.Group>
                   
                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="4.5s" controlId='exampleForm.ControlTextarea1'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      name="message"  // Ensure this matches the key in the state
                      value={userData.message}
                      onChange={changeInputhandler}
                      placeholder='Write your message'
                    />
                  </Form.Group>
                    <div className="btnDiv">
                    <button type='submit' className='btn btn-primary wow animate__fadeInUp' data-wow-duration="5s">Submit Form</button>
                    </div>
                  </Form>

                </div>
              </div>
            </div>
        </section>

        {/* <section>
          <div className="container">
            <div className="contactUs_textArea">
              <div className="contactUs_reactOut">
                <p className='react_out wow animate__fadeInUp' data-wow-duration="4.5s">React Out To Us</p>
              </div>

              <div className="contactUs_hear_From_you wow animate__fadeInUp" data-wow-duration="4.8s">
                <h3>We'd Love To Hear From You.</h3>
              </div>
              
              <p className=' wow animate__fadeInUp' data-wow-duration="5.2s">Or just reach out manually on <a href="mailto:grajahmic@gmail.com">grajahmichomes@gmail.com</a></p>
            </div>
          </div>
        </section> */}


        <Footer/>
    
    </div>
  )
}

export default ContactUs