import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import ReactDOM from 'react-dom/client';
import './index.css';  
import Home from './pages/home';
import About from './pages/aboutUs';
import Contact from './pages/contactUs';
import Properties from './pages/properties';
import Services from './pages/services';
import Marketers from './pages/Marketers';
import SpringDetail from './pages/SpringDetail.jsx';
import ErrorPage from './pages/ErrorPage';
import PropertyDetails from './pages/propertyDetails.jsx';
import PrimeEstateDetail from './pages/primeEstateDetail.jsx';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage/>,
     children: [
      {index: true, element: <Home/>},
      {path: "about", element: <About/>},
      {path: "properties", element: <Properties/>},
      {path: "contact_us", element: <Contact/>},
      {path: "services", element: <Services/>},
      {path:'Marketers', element:<Marketers/>},
      {path: "/premium_estate_detail", element: <PropertyDetails/>},
      {path: "/prime_estate_detail", element: <PrimeEstateDetail/>},
      {path: "/spring_view_estate_detail", element: <SpringDetail/>},
     ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
